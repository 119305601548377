$(document).ready(function () {
	//Wrap navigation to more dropdown link
	$("nav.nav-sec").css("display", "flex");
	$("nav.nav-sec ul.nav-list").navwrap();


	if ($(".propholder").length) {
		$(".propholder").each(function () {
			$(this).height($(this).outerHeight());
		});
	}

	if ($(".testimonial-list-container").length > 0) {
		$(".testimonial-list-container .testimonial").show();
		$(".testimonial-list-container").carouFredSel({
			circular: true,
			infinite: false,
			align: "left",
			height: 150,
			responsive: true,
			scroll: { 
				items: 1,
				duration: 1000
			},
			pagination: "#foo2_pag"
		});
	}
});
